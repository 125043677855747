<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button
            variant="white"
            class="btn-icon rounded-circle mr-50 text-danger bg-white"
            size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span>
        Detalles obra
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editProject">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-group :label="'Nombre'" label-for="project-name">
                  <b-form-input
                    v-model="project.name"
                    name="name"
                    :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                :name="'SKU'"
                rules="required"
              >
                <b-form-group :label="'Código obra'" label-for="project-SKU">
                  <b-form-input
                    v-model="project.SKU"
                    name="Código obra"
                    :placeholder="'Código obra'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                :name="'Estado'"
                rules="required"
              >
                <b-form-group :label="'Estado'" label-for="project-status">
                  <v-select
                    label="alias"
                    :filterable="false"
                    :searchable="false"
                    :options="selectStatus"
                    :placeholder="'Estado'"
                    v-model="status"
                  >
                    <template slot="option" slot-scope="option">
                      {{ statusProjectsText[option.alias] }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ statusProjectsText[option.alias] }}
                    </template>
                  </v-select>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                :name="'Cliente'"
                rules="required"
              >
                <b-form-group :label="'Cliente'" label-for="project-client">
                  <v-select
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectClients"
                    :placeholder="'Cliente'"
                    v-model="project.client"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-form-group
                :label="'Descripción'"
                label-for="project-description"
              >
                <quill-editor v-model="project.description" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="'Documentos'" label-for="project-documents">
                <ImageDropzone
                  ref="documents"
                  :files="files"
                  maxFiles="10"
                  v-if="projectPrepare"
                />
              </b-form-group>
              <b-row>
                <b-col sm="12" class="pb-1"> Duración obra </b-col>
                <b-col sm="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="'Fecha de inicio'"
                    rules="required"
                  >
                    <b-form-group
                      :label="'Fecha de inicio'"
                      label-for="project-fechaini"
                    >
                      <flat-pickr
                        v-model="date_ini"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col sm="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="'Fecha de fin'"
                    rules="required"
                  >
                    <b-form-group
                      :label="'Fecha de fin'"
                      label-for="project-fechafin"
                    >
                      <flat-pickr
                        v-model="date_fin"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" class="pb-1"> Cobertura de seguro </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="'Fecha de inicio'"
                    label-for="project-date_ini_seguro"
                  >
                    <flat-pickr
                      v-model="date_ini_seguro"
                      name="fecha_ini"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="'Fecha de fin'"
                    label-for="project-date_fin_seguro"
                  >
                    <flat-pickr
                      v-model="date_fin_seguro"
                      name="fecha_ini"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" class="pb-1"> Apertura centro de trabajo </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="'Fecha de inicio'"
                    label-for="project-date_ini_centro"
                  >
                    <flat-pickr
                      v-model="date_ini_centro"
                      name="fecha_ini"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="'Fecha de fin'"
                    label-for="project-date_fin_centro"
                  >
                    <flat-pickr
                      v-model="date_fin_centro"
                      name="fecha_ini"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="6">
              <div sm="12" class="pb-1">Recurso Preventivo en obra</div>
              <div class="repeater-form">
                <b-row
                  v-for="(item, index) in items"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <!-- Item Name -->
                  <b-col md="5">
                    <b-form-group label="Nombre" label-for="item-name">
                      <b-form-input
                        :id="item.name"
                        v-model="item.name"
                        type="text"
                        placeholder="Nombre"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group label="Teléfono" label-for="item-phone">
                      <b-form-input
                        :id="item.phone"
                        v-model="item.phone"
                        type="text"
                        placeholder="Teléfono"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="1" md="1" class="mb-50">
                    <span
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="d-inline-block px-50 text-danger mt-2 icon-trash"
                      @click="removeItem(index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </span>
                  </b-col>
                </b-row>
                <b-button
                  variant="outline-danger"
                  @click="repeateAgain"
                  class="mb-2"
                  :disabled="isDisabled"
                >
                  <span>Añadir</span>
                </b-button>
              </div>

              <div sm="12" class="pb-1">Coordinador de Seguridad y Salud</div>
              <div class="repeater-form">
                <b-row>
                  <!-- Item Name -->
                  <b-col md="5">
                    <b-form-group label="Nombre" label-for="item-name">
                      <b-form-input
                        id="item-name"
                        type="text"
                        placeholder="Nombre"
                        v-model="project.person_coordinador_seguridad_salud"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group label="Teléfono" label-for="item-name">
                      <b-form-input
                        id="item-name"
                        type="text"
                        placeholder="Teléfono"
                        v-model="project.phone_coordinador_seguridad_salud"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col sm="6">
              <b-row>
                <b-col sm="12" class="pt-1">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="project.subcontrata"
                      size="sm"
                      class="check-subcontrata"
                      :value="project.subcontrata"
                    >
                      Subcontrata
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group
                :label="'Importe facturado'"
                label-for="project-importe_facturado"
                v-if="role == 'admin' || role == 'super_admin'"
              >
                <b-input-group append="€">
                  <b-form-input
                    v-model="project.importe_facturado"
                    name="Importe facturado"
                    :placeholder="'0'"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group
                :label="'Importe pendiente'"
                label-for="project-importe_pendiente"
                v-if="role == 'admin' || role == 'super_admin'"
              >
                <b-input-group append="€">
                  <b-form-input
                    v-model="project.importe_pendiente"
                    name="Importe pendiente"
                    :placeholder="'0'"
                  />
                </b-input-group>
              </b-form-group>
              <b-row>
                <b-col sm="12" class="pb-1"> Departamentos </b-col>
                <b-col
                  sm="4"
                  v-if="item.active"
                  :key="item.id"
                  v-for="item in selectDepartments"
                >
                  <b-form-checkbox
                    v-model="departments"
                    :value="item.id"
                    size="sm"
                    class="check-departamentos"
                  >
                    {{ item.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                {{ "Enviar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BInputGroup,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp, TimestampToFlatPickr } from "@/libs/helpers";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import { config } from "@/shared/app.config";
import { heightTransition } from "@core/mixins/ui/transition";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    ImageDropzone,
    config,
    BFormCheckbox,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      statusProjectsText: config.statusProjectsText,
      date_ini: "",
      date_fin: "",
      date_ini_seguro: "",
      date_fin_seguro: "",
      date_ini_centro: "",
      date_fin_centro: "",
      departments: [],
      files: [],
      status: {},
      required,
      email,
      projectPrepare: false,
      isDisabled: false,
      nextTodoId: 2,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      project: "projects/getProject",
      selectStatus: "projects/getSelectStatus",
      selectDepartments: "departments/getSelectDepartments",
      selectClients: "clients/getSelectClients",
      role: "auth/getRole",
    }),
  },
  methods: {
    ...mapActions({
      edit: "projects/edit",
      getProject: "projects/getProject",
      getSelectStatus: "projects/selectStatus",
      getSelectDepartments: "departments/selectDepartments",
      getSelectClients: "clients/selectClients",
    }),
    repeateAgain() {
      if (this.items.length < 3) {
        this.items.push({
          id: this.nextTodoId + 1,
          name: "",
          phone: "",
        });
        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight);
        });
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    removeItem(index) {
      this.items.splice(index, 1);
      if (this.items.length < 3) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
      if (this.$refs.row[0].offsetHeight) {
        this.trTrimHeight(this.$refs.row[0].offsetHeight);
      }
    },
    handleSubmit() {
      this.$refs.editProject.validate().then((success) => {
        if (success) {
          const { id } = this.project;
          const formData = this.createFormData();
          if (formData) {
            this.edit({ id, project: formData });
          }
        }
      });
    },
    setData() {
      if (this.project.recurso_preventivo) {
        let valores = this.items;
        this.project.recurso_preventivo.forEach(function callback(
          currentValue,
          index,
          array
        ) {
          let val = JSON.parse(currentValue);

          valores.push({
            id: index,
            name: val["name"],
            phone: val["phone"],
          });
        });
      }

      if (this.project.date_ini) {
        this.date_ini = TimestampToFlatPickr(this.project.date_ini);
      }
      if (this.project.date_fin && this.project.date_fin !== "null") {
        this.date_fin = TimestampToFlatPickr(this.project.date_fin);
      }
      if (
        this.project.date_ini_seguro &&
        this.project.date_ini_seguro !== "null"
      ) {
        this.date_ini_seguro = TimestampToFlatPickr(
          this.project.date_ini_seguro
        );
      }
      if (
        this.project.date_fin_seguro &&
        this.project.date_fin_seguro !== "null"
      ) {
        this.date_fin_seguro = TimestampToFlatPickr(
          this.project.date_fin_seguro
        );
      }
      if (
        this.project.date_ini_centro &&
        this.project.date_ini_centro !== "null"
      ) {
        this.date_ini_centro = TimestampToFlatPickr(
          this.project.date_ini_centro
        );
      }
      if (
        this.project.date_fin_centro &&
        this.project.date_fin_centro !== "null"
      ) {
        this.date_fin_centro = TimestampToFlatPickr(
          this.project.date_fin_centro
        );
      }
      if (this.project.documents.length > 0) {
        const aux = this;
        this.project.documents.forEach((element, index) => {
          const file = {
            name: `File ${index + 1}`,
            url: element.path,
            urlDelete: `/projects/delete_doc/${aux.project.id}/${element.id}`,
          };
          this.files.push(file);
        });
      }
      if (this.project.departments.length > 0) {
        this.project.departments.forEach((element, index) => {
          this.departments.push(element.id);
        });
      }
      if (this.status) {
        this.status = this.project.status;
      }
      this.projectPrepare = true;
    },
    createFormData() {
      const data = this.$refs.documents.getFormData("documents");
      data.append("date_ini", FlatPickrToTimestamp(this.date_ini));
      if (this.date_fin) {
        data.append("date_fin", FlatPickrToTimestamp(this.date_fin));
      } else {
        data.append("date_fin", null);
      }
      if (this.date_ini_seguro) {
        data.append(
          "date_ini_seguro",
          FlatPickrToTimestamp(this.date_ini_seguro)
        );
      } else {
        data.append("date_ini_seguro", null);
      }
      if (this.date_fin_seguro) {
        data.append(
          "date_fin_seguro",
          FlatPickrToTimestamp(this.date_fin_seguro)
        );
      } else {
        data.append("date_fin_seguro", null);
      }
      if (this.date_ini_centro) {
        data.append(
          "date_ini_centro",
          FlatPickrToTimestamp(this.date_ini_centro)
        );
      } else {
        data.append("date_ini_centro", null);
      }
      if (this.date_fin_centro) {
        data.append(
          "date_fin_centro",
          FlatPickrToTimestamp(this.date_fin_centro)
        );
      } else {
        data.append("date_fin_centro", null);
      }
      if (this.departments.length > 0) {
        console.log(this.departments);
        this.departments.forEach((element) => {
          data.append("departments[]", element);
        });
      } else {
        data.append("departments", "");
      }
      if (this.project.importe_facturado) {
        data.append("importe_facturado", this.project.importe_facturado);
      } else {
        data.append("importe_facturado", null);
      }
      if (this.project.importe_pendiente) {
        data.append("importe_pendiente", this.project.importe_pendiente);
      } else {
        data.append("importe_pendiente", null);
      }

      if (this.items.length > 0) {
        for (let f in this.items) {
          if (this.items[f]["name"] != "") {
            data.append(
              "recurso_preventivo[]",
              JSON.stringify({
                name: this.items[f]["name"],
                phone: this.items[f]["phone"],
              })
            );
          }
        }
      }

      data.append("name", this.project.name);
      data.append("status", this.status);
      data.append("SKU", this.project.SKU);
      data.append("client_id", this.project.client.id);
      data.append("description", this.project.description);
      data.append("subcontrata", this.project.subcontrata);
      data.append(
        "person_coordinador_seguridad_salud",
        this.project.person_coordinador_seguridad_salud
      );
      data.append(
        "phone_coordinador_seguridad_salud",
        this.project.phone_coordinador_seguridad_salud
      );

      return data;
    },
  },
  async created() {
    await this.getProject(this.$route.params.id);
    await this.getSelectStatus();
    await this.getSelectDepartments({});
    await this.getSelectClients();
    await this.setData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.icon-trash {
  padding-top: 7px;
}
</style>
